import React, { useState } from 'react';

import { Link, useHistory } from "react-router-dom";
// import Sheet from 'react-modal-sheet';
import { HiCalendar, HiLocationMarker, HiX } from 'react-icons/hi';
import { useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';
import Const, { Config, listMall } from '../../utils/const';
import { useDispatch, useSelector } from 'react-redux';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { setPrimaryColor, setSecondaryColor, setSelectedMall, setTenants } from '../../store/app/adsign/actions';
import { setActivePage, setIsMainOpen, setSelectedPage } from '../../store/app/appActions';
import { clearSessionStorageOnHotReload } from '../../utils/clearSessionStorageOnHotReload';
import { capitalandS3Url } from '../../utils/constants';
import { resetConfig } from '../../store/app/capitalandActions';

function Header({ isHide = false }) {
    const navigate = useHistory();
    // const selectedMall = localStorage.getItem('selectedMall');
    const [isModalMallSelectorOpen, setisModalMallSelectorOpen] = useState(false);
    const dispatch = useDispatch();
    const { config } = useSelector((state) => ({
        config: state.capitaland.config,
    }));

    const [isShowModalConf, setisShowModalConf] = useState(false);
    return (
        <React.Fragment>
            <div className='w-screen p-2 flex flex-row items-center justify-between z-10 bg-white' style={{ opacity: isHide ? 0 : 1 }}>
                <div></div>
                <img src={config ? `${capitalandS3Url}/media/${config.logo}` : require("../../img/logo.png")}
                    className='w-32 h-12 object-contain' onClick={() => {
                        dispatch(setIsMainOpen(true));
                        dispatch(setSelectedPage('main'));
                    }} />
                <img src={require('../../assets/images/icons/mall-selection.png')} className='w-[26px] h-[26px] object-contain' onClick={() => {
                    
                    setisShowModalConf(true);
                }} />
            </div>

            {/* <BottomSheet
                open={isModalMallSelectorOpen}
                snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1.18]}
                // Opens the largest snap point by default, unless the user selected one previously
                defaultSnap={({ lastSnap, snapPoints }) =>
                    lastSnap ?? Math.max(...snapPoints)
                }
                draggable={false}
                className='absolute z-10'
                header={
                    <div className='flex flex-row justify-between p-3'>
                        <span className=' font-semibold'>Select Mall</span>
                        {config != null && <HiX fontSize={20} onClick={() => {
                            setisModalMallSelectorOpen(false)
                        }} />}
                    </div>
                }
            >

                <div className='flex flex-col-reverse w-full h-full'>
                    <div className='flex-grow w-full flex-col overflow-scroll bg-gray-200 p-3'>
                        <div className='grid grid-cols-3 gap-3'>
                            {sites.map((item, idx) => {
                                return <div className='rounded-lg bg-white flex flex-col' key={idx} onClick={() => {
                                    sessionStorage.setItem('selectedMall', JSON.stringify(item));
                                    dispatch(setSelectedMall(item));
                                    dispatch(setPrimaryColor(item.config != null ? item.config.primaryColor : Const.mainColor))
                                    dispatch(setSecondaryColor(item.config != null ? item.config.secondaryColor : Const.secondaryColor))
                                    dispatch(setTenants([]));
                                    navigate.push('/');
                                }}>
                                    <img src={item.config != null ? `${Config.api.s3Url}/media/${item.config.logo}` : require('../../assets/images/logo.png')} className='aspect-square object-contain m-4' />
                                    <span className='text-center text-sm p-3 font-semibold'>{item.name}</span>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className='flex flex-row items-center gap-3 p-3'>
                        <div className="relative flex-grow">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <FiSearch fontSize={20} color='grey' />
                            </div>
                            <input type="search" id="search" autoComplete='off' className="block w-full p-3 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50" placeholder="Search Mall, Brand or Restaurant..." required />
                        </div>
                    </div>
                </div>

            </BottomSheet> */}
            {isShowModalConf && <div className='w-screen h-screen fixed z-[100] bg-[#00000042] flex flex-col items-center justify-center p-4'>
                <div className='bg-white w-full rounded-lg p-4 flex flex-col'>
                    <b>Proceed to CapitaLand mall selections?</b>
                    <p>You will be redirected to a new page to select a new CapitaLand mall website. Would you like to continue?</p>
                    <div className='flex flex-row mt-3'>
                        <button type="button" onClick={() => {
                            setisShowModalConf(false);
                        }} className="text-white font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 " style={{ backgroundColor: config.secondaryColor }}>Cancel</button>
                        <button type="button" onClick={() => {
                            clearSessionStorageOnHotReload()
                            dispatch(resetConfig());
                            sessionStorage.clear();
                            window.location.href = "/";
                        }} className={`text-white font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2`} style={{ backgroundColor: config.primaryColor }} >Redirect Me</button>
                    </div>
                </div>
            </div>}
        </React.Fragment>
    );
}

export default Header;