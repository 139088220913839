import React from "react";
import Lottie from "react-lottie";
import ErrorIcon from "../img/animation/error.json";
import { connect } from "react-redux";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, countDown: 10 };
    this.timer = null;
  }

  tick() {
    const current = this.state.countDown;
    if (current === 0) {
      this.transition();
    } else {
      this.setState({ ...this.state, countDown: current - 1 });
    }
  }

  transition() {
    clearInterval(this.timer);
    // this.handleReload();
    // do something else here, presumably.
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidUpdate() {
    this.timer = setInterval(() => this.tick(), 1000);
  }

  static getDerivedStateFromError(error) {
    
    // Update state so the next render will show the fallback UI.
    return { hasError: true, countDown: 3 };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    // this.setState({
    //   ...this.state,
    //   countDown: 10,
    // });

    
  }

  handleReload = () => {
    // window.location.reload(false); 
  };

  render() {
    
    if (this.state.hasError || this.props.isError) {
      // You can render any custom fallback UI
      return (
        <div className="flex flex-1 p-8 w-full h-full justify-center items-center">
          <div className="flex flex-col">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: ErrorIcon,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              width={150}
              height={150}
            />
            <h1 className="text-primary mt-10 text-2xl mb-8 font-bold">
              Whoops! Something went wrong!
            </h1>
            <p className="text-gray-700 text-2xl">
              Will go home after {this.state.countDown} seconds
            </p>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

const mapStateToProps = (state) => ({
  isError: state.errors.isError,
});

export default connect(mapStateToProps)(ErrorBoundary);
