/* eslint-disable no-use-before-define */
/* eslint-disable linebreak-style */
import React, { Fragment, useEffect, useRef } from "react";
import ErrorBoundary from "./components/ErrorBoundary";
import Routes from "./routes";
import { clearSessionStorageOnHotReload } from './utils/clearSessionStorageOnHotReload';
import { BrowserView, MobileView } from "react-device-detect";
import DesktopRoutes from "./routes/desktopRoutes";
// const Main = () => {
//   return <div>
//     lorem
//   </div>;
// };
clearSessionStorageOnHotReload();
const App = () => (
    <Fragment>
        <Routes />
    </Fragment>
);

export default App;
