import axios from "axios";
import { url, email, password } from "../../utils/constants";
import { getConfig } from "./capitalandActions";

export const login = siteId => {
  return async (dispatch) => {
    
    dispatch({ type: "LOADING_START" });
    try {
      const res = await axios.post(`${url}/auth/signin`, { email, password });
      dispatch({ type: "LOGIN", payload: res.data.accessToken });
    } catch (err) {
      
    }
    dispatch({ type: "LOADING_FINISH" });
  };
};
