import axios from "axios";
import { baseUrl, siteId } from "../../utils/constants";
import { login } from "./authActions";

export const getSite = (siteName) => {
  return async (dispatch, getState) => {
    dispatch({ type: "LOADING_START" });
    try {
      const token = getState().auth.token;
      
      const res = await axios.get(`${baseUrl}/site`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = res.data.data.filter((data)=>data.name == siteName)[0];
      dispatch({ type: "SET_SITE", payload: data });
    } catch (err) {
      
    }
    dispatch({ type: "LOADING_FINISH" });
  };
};

export const getConfig = () => {
  return async (dispatch, getState) => {
    dispatch({ type: "LOADING_START" });
    try {
      const token = getState().auth.token;
      const siteId = getState().capitaland.currentSite['_id'];
      
      const res = await axios.get(`${baseUrl}/configuration`, {
        params: {
          siteId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = res.data.data;
      
      dispatch({ type: "SET_CONFIG", payload: data });
    } catch (err) {
      
    }
    dispatch({ type: "LOADING_FINISH" });
  };
};

export const resetConfig = () => (dispatch) => {
  dispatch({ type: "RESET_ALL"  });
}

export const setSelectedTransport = (value) => (dispatch) => {
  dispatch({
    type: "SET_SELECTED_TRANSPORT",
    payload: value,
  });
};

export const setSelectedStore = (data) => (dispatch) => {
  dispatch({
    type: "SET_SELECTED_STORE",
    payload: data,
  });
};

export const setSelectedCategories = (data) => (dispatch) => {
  dispatch({
    type: "SET_SELECTED_CATEGORIES",
    payload: data,
  });
};

export const setSelectedLevels = (data) => (dispatch) => {
  dispatch({
    type: "SET_SELECTED_LEVELS",
    payload: data,
  });
};

export const setSelectedFashionCategory = (data) => (dispatch) => {
  dispatch({
    type: "SET_SELECTED_FASHION_CATEGORY",
    payload: data,
  });
};

export const setSelectedDineCategory = (data) => (dispatch) => {
  
  dispatch({
    type: "SET_SELECTED_DINE_CATEGORY",
    payload: data,
  });
};

export const setSelectedDineCuisine = (data) => (dispatch) => {
  dispatch({
    type: "SET_SELECTED_DINE_CUISINE",
    payload: data,
  });
};

export const setSelectedDineFoodType = (data) => (dispatch) => {
  dispatch({
    type: "SET_SELECTED_DINE_FOOD_TYPE",
    payload: data,
  });
};

export const setToggleDineCategory = (data, cb) => (dispatch) => {
  dispatch({
    type: "SET_TOGGLE_DINE_CATEGORY",
    payload: data,
  });
  if (cb) {
    cb();
  }
};

export const setToggleDineCuisine = (data, cb) => (dispatch) => {
  dispatch({
    type: "SET_TOGGLE_DINE_CUISINE",
    payload: data,
  });
  if (cb) {
    cb();
  }
};

export const setToggleDineFoodType = (data, cb) => (dispatch) => {
  dispatch({
    type: "SET_TOGGLE_DINE_FOOD_TYPE",
    payload: data,
  });
  if (cb) {
    cb();
  }
};

export const setToggleDineVibes = (data, cb) => {
  return (dispatch) => {
    dispatch({
      type: "SET_TOGGLE_DINE_VIBES",
      payload: data,
    });
    if (cb) {
      cb();
    }
  };
};

export const setSelectedDineVibes = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECTED_DINE_VIBES",
      payload: data,
    });
  };
};

export const setSelectedTourist = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECTED_TOURIST",
      payload: data,
    });
  };
};

export const setSelectedMall = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECTED_MALL",
      payload: data,
    });
  };
};
