import React from 'react';
import { Link, NavLink, useHistory } from "react-router-dom";

//i18n
import Header from './Header';

function NotFound({title,description}) {
    const navigate = useHistory();
    return (
        <React.Fragment>
            <Header/>
            <div className='w-screen h-screen flex flex-col items-center justify-center'>
                <img src={require('../../assets/images/icons/search.png')} className='aspect-image w-40' />
                <b>Error 404</b>
                <span>We can't find the page you looking for</span>
            </div>
        </React.Fragment>
    );
}

export default NotFound;