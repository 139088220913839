import React, { Suspense, useEffect, useState } from 'react';
import { Switch as SwitchRoute, Route, Redirect, useLocation } from 'react-router-dom';

//import routes
import { authProtectedRoutes, protectedRoutes, publicRoutes, routes } from './routes';
import NotFound from '../components/capitaland/NotFound';
import Header from '../components/capitaland/Header';


const AuthProtected = (props) => {

    /*
      Redirect is un-auth access protected routes via url
      */

    if (props.isAuthProtected && !sessionStorage.getItem("selectedMall")) {
        window.location.href = "/";
        // return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
    }

    return <>{props.children}</>;
};

/**
 * Main Route component
 */
const Routes = () => {
    const location = useLocation();
    const [screenHeightWithoutAddressBar, setScreenHeightWithoutAddressBar] = useState(window.innerHeight);
    useEffect(() => {
        const handleResize = () => {
          setScreenHeightWithoutAddressBar(window.innerHeight);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    return (
        
        <Suspense fallback={<div></div>} >
                <SwitchRoute>
                    {routes.map((route, idx) =>
                        <Route key={idx} exact path={route.path}>
                            <style>
                                {`
                                    .h-screen {
                                    height : ${screenHeightWithoutAddressBar}px!important;
                                    }
                                `}
                            </style>
                            <div className="h-screen w-screen flex flex-col overflow-hidden">
                                {/* {location.pathname != '/' && <Header />} */}
                                {route.component}
                            </div>
                        </Route>
                    )}

                    {protectedRoutes.map((route, idx) =>
                        <Route key={idx} exact path={route.path}>
                            <AuthProtected isAuthProtected={true}>
                                <div className="h-screen w-screen flex flex-col overflow-hidden">
                                    {location.pathname != '/' && <Header />}
                                    {route.component}
                                </div>
                            </AuthProtected>
                        </Route>
                    )}
                    <Route path="*" Component={NotFound} />
                </SwitchRoute>
            </Suspense>
    );
}

export default Routes;