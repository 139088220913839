import React from "react";
import { Navigate } from "react-router-dom";
// import FloorGuide from "../pages/floor_guide";

// lazy load all the views
const Splashscreen = React.lazy(() => import("../pages/splashscreen/index"));
const Dashboard = React.lazy(() => import("../pages/dashboard/index"));
// const Directory = React.lazy(() => import("../pages/directory/index"));
// const Fashion = React.lazy(() => import("../pages/fashion/index"));
// const Dine = React.lazy(() => import("../pages/dine/index"));
// const Promotions = React.lazy(() => import("../pages/promotions/index"));
// const Rewards = React.lazy(() => import("../pages/rewards/index"));
// const Happenings = React.lazy(() => import("../pages/happenings/index"));
// const Concierge = React.lazy(() => import("../pages/concierge/index"));
// const TouristProgramme = React.lazy(() => import("../pages/tourist_programme/index"));
// const FloorGuide = React.lazy(() => import("../pages/floor_guide/index"));
// const Transport = React.lazy(() => import("../pages/transport/index"));
// const CarparkRates = React.lazy(() => import("../pages/carpark-rates/index"));
// const PublicInformation = React.lazy(() => import("../pages/public_information/index"));
const Landing = React.lazy(() => import("../pages/Landing/index"));



// const SplashscreenDesktop = React.lazy(() => import("../desktopView/splashscreen/index"));
// const DashboarDesktop = React.lazy(() => import("../desktopView/dashboard/index"));
// const Directory = React.lazy(() => import("../pages/directory/index"));
// const Fashion = React.lazy(() => import("../pages/fashion/index"));
// const Dine = React.lazy(() => import("../pages/dine/index"));
// const Promotions = React.lazy(() => import("../pages/promotions/index"));
// const Rewards = React.lazy(() => import("../pages/rewards/index"));
// const Happenings = React.lazy(() => import("../pages/happenings/index"));
// const Concierge = React.lazy(() => import("../pages/concierge/index"));
// const TouristProgramme = React.lazy(() => import("../pages/tourist_programme/index"));
// const FloorGuide = React.lazy(() => import("../pages/floor_guide/index"));
// const Transport = React.lazy(() => import("../pages/transport/index"));
// const CarparkRates = React.lazy(() => import("../pages/carpark-rates/index"));
// const PublicInformation = React.lazy(() => import("../pages/public_information/index"));

const routes = [
  { path: "/", component: <Splashscreen/>,},
  { path: "/dashboard", component: <Dashboard/>,},
  { path: "/:site/:id?", component: <Landing/>,}, 
];

const protectedRoutes = [
  // { path: "/directory", component: <Directory/>,},
  // { path: "/fashion", component: <Fashion/>,},
  // { path: "/dine", component: <Dine/>,},
  // { path: "/promotions", component: <Promotions/>,},
  // { path: "/rewards", component: <Rewards/>,},
  // { path: "/happenings", component: <Happenings/>,},
  // { path: "/concierge", component: <Concierge/>,},
  // { path: "/tourist-programme", component: <TouristProgramme/>,},
  // { path: "/floor-guide", component: <Dashboard/>,},
  // { path: "/transport", component: <Transport/>,},
  // { path: "/carpark-rates", component: <CarparkRates/>,},
  // { path: "/public-transport", component: <PublicInformation/>,},
];

const desktopRoutes = [
  // { path: "/", component: <SplashscreenDesktop/>,},
  // { path: "/dashboard", component: <DashboarDesktop/>,},
];

const desktopProtectedRoutes = [
  // { path: "/directory", component: <Directory/>,},
  // { path: "/fashion", component: <Fashion/>,},
  // { path: "/dine", component: <Dine/>,},
  // { path: "/promotions", component: <Promotions/>,},
  // { path: "/rewards", component: <Rewards/>,},
  // { path: "/happenings", component: <Happenings/>,},
  // { path: "/concierge", component: <Concierge/>,},
  // { path: "/tourist-programme", component: <TouristProgramme/>,},
  // { path: "/floor-guide", component: <Dashboard/>,},
  // { path: "/transport", component: <Transport/>,},
  // { path: "/carpark-rates", component: <CarparkRates/>,},
  // { path: "/public-transport", component: <PublicInformation/>,},
];


export {routes , protectedRoutes,desktopProtectedRoutes,desktopRoutes};
